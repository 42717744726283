.connect4 {
  margin-right: 10%;
  margin-left: 0;
  width: auto; /* Ensure the container fills the available space */
}

.connect4-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* Make columns flexible */
  grid-template-rows: repeat(6, 1fr); /* Make rows flexible */
  gap: 5px;
  margin: 10px auto;
  max-width: 100%; /* Ensure the grid doesn’t exceed the container width */
}

.connect4-cell {
  width: auto; /* Fill the grid cell */
  padding-top: auto; /* Maintain aspect ratio by setting padding-top equal to width */
  position: relative; /* Ensure the inner content is positioned correctly */
  background-color: white;
  border: 2px solid #333;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, border-color 0.1s ease-in-out;
}



.agent-selection h2{
  margin-bottom: 0;
}

.agents{
  display: flex;
  gap: 0rem 1rem;
  margin-top: 0;
}

.depth-select{
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 0.5rem;
}

.connect4-cell::after {
  content: "";
  display: block;
  padding-top: 100%; /* Maintains square aspect ratio */
}

.connect4-cell > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.connect4-cell:hover {
  background-color: lightgray;
}

.board button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid transparent; /* Default border */
  border-radius: 5px;
}

.board {
  margin-top: 5%;
}

.agent-selection button {
  font-size: 16px;
  cursor: pointer;
  border: 2px solid transparent; /* Default border */
  border-radius: 5px;
}

.depth-select button {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  cursor: pointer;/* Default border */
  border-radius: 5px;
  border: 2px solid transparent; /* Default border */
  background-color: #f0f0f0;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.agent-selection button {
  padding: 10px 20px;
  margin-top: 20px;
  border: 2px solid transparent; /* Default border */
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.agent-selection button.selected {
  border-color: #ff2f00; /* Highlight color */
  background-color: #aaadb1; /* Slight background color change */
}

.depth-select button.selected {
  border-color: #ff2f00; /* Highlight color */
  background-color: #aaadb1; /* Slight background color change */
}

.agent-selection button:hover {
  background-color: #b8b8b8;
}

/* Responsive Design: Media Queries */
@media (max-width: 768px) {

  .connect4-grid {
    grid-template-columns: repeat(7, 1fr); /* Flexible columns */
    grid-template-rows: repeat(6, 1fr); /* Flexible rows */
    gap: 2px; /* Reduce gap on smaller screens */
  }

  .connect4-cell {
    border-width: 1px; /* Reduce border width on smaller screens */
  }

  button, .agent-selection button {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 8px 12px;
  }
}