@import url('https://fonts.googleapis.com/css2?family=Radio+Canada+Big:ital,wght@0,400..700;1,400..700&display=swap');

* {
    box-sizing: border-box;
    font-family: "Radio Canada Big", sans-serif;
}

body {
    margin: 0;
    background: #333;
}

html {
    scroll-behavior: smooth;
}

.container {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 1rem;
    color: #ffffff;
}

.navbar{
    display: flex;
    border-style: solid;
    justify-content: space-between;
    position: sticky;
    top: 0;
    border-width: 1;
    border-color: #1e1e1e;
    background-color: #333;
    width: 100%;
    z-index: 9999;
}

/* Navbar container */
.navbar-div{
    display: flex;
    position: sticky;
    top: 0;
    justify-content: space-between;
    gap: 1rem 1rem;
    background-color: #333;
    color: #fff;
    margin-left: 10%;
    margin-right: 1%;
    margin-bottom: 1%;
    align-items: flex-start;
    width: 100%;
  }

  .hamburger-menu {
    display: none;
    font-size: 24px;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
}

.contact-info{
    display: grid;
}

.site-title {
    display: grid;
    flex-direction: column;
    font-size: 2rem;
    gap: 0;
    margin-top: 1%;
}

.page-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;  /* Allow items to wrap on smaller screens */
    padding: 0;
    gap: 1rem 3rem;
    font-size: large;
}

.navbar a {
    color: inherit;
    text-decoration: none;
}

.navbar li{
    display: inline;
}

.navbar li:hover {
    color: #575757;
}

.navbar li.active {
    text-decoration: underline;
    text-decoration-color: rgb(228, 21, 21);
    text-decoration-thickness: 30%;
}

.footer {
    display: flex;
    align-self: center;
    justify-content: center;
    margin-top: 15%;
    margin-bottom: 1%;
    text-align: center;
    color: #141414;
}

.home-buttons button.selected{
    border-color: #ff2f00;
    
 }

.burger{
    margin-right: 10%;
}

/* home styling */
.home-section-content-box{
    display: inherit;
    animation-fill-mode: both;
    align-items: flex-start;
}

.home-section-content {
    margin-right: auto;
}

.home-section-info {
    display: flex;
    gap: 1rem;
}

.home-buttons button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: 2px solid transparent; /* Default border */
    border-radius: 5px;
  }

.me-image{
    margin-top: 1rem;
    border:#ffffff;
    border-style: solid;
    max-width: 80%;
    height: 80%;
}

.home-buttons{
    display: flex;
    list-style: none;
    padding: 0;
    margin-top: 5%;
    gap: 0rem 1rem;
}

button.selected{
    border-color: #ff2f00;
 }

@media (max-width: 1090px) {
    .home-section-info{
        margin-right: auto;
        display: inherit;
        animation-fill-mode: both;
        align-items: flex-start;
    }
}

/* Responsive Design: Media Queries */
@media (max-width: 768px) {
    .hamburger-menu {
        display: flex;
        align-self: flex-start;
        margin: 1.5rem;
    }
    
    .home-section-info{
        margin-right: auto;
        display: inherit;
        animation-fill-mode: both;
        align-items: flex-start;
    }

    .home-buttons{
        display:inline-flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 0rem 0.5rem;
    }
    
    .about-section-content-box{
        display: inherit;
        align-items: start;
        margin-right: 10%;
    }
    
}

.connect4-div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


/* about styling */
.about-section-content-box{
    display: flex;
    align-items: start;
    margin-right: 10%;
}

.about-image{
    border:#ffffff;
    border-style: solid;
}

/* project styling */
/* General Project Section Styling */
.project-section-content-box {
    font-size: 1rem;
    color: #ffffff;
}

.project-section-content {
    display: flex;
    flex-direction: column; /* Stack the project items on top of each other for better mobile responsiveness */
    gap: 1rem; /* Space between project items */
}

/* Project List */
.project-list {
    padding: 0;
    list-style: none;
    margin: 0;
}

/* Individual Project */
.project-list li {
    margin-bottom: 2rem; /* Space between projects */
}

/* Project Container */
.project-div {
    display: grid;
    background-color: #282828;
    border: 1px solid #484848;
    padding: 1rem;
    gap: 1rem;
    grid-template-columns: 1fr 1fr; /* Two columns layout */
    align-items: start;
}

/* Project Text */
.project-text {
    padding: 0 1rem;
}

/* Project Images */
.project-images {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.project-images img {
    max-width: 100%;
    height: auto;
    border: 1px solid #484848;
}

.image-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Responsive Design: Media Queries */
@media (max-width: 768px) {
    .project-section-content-box {
        margin: 0 5%;
    }

    .project-div {
        grid-template-columns: 1fr; /* Switch to single-column layout */
    }

    .project-images {
        padding: 0;
    }

    .project-text {
        padding: 0;
    }
}


.project-list p a{
    color:rgb(3, 159, 250);
}

.projLink {
    color: inherit;
}

.project-images {
    display: contents;
    align-items: end;
}

.image-list{
    list-style: none;
    align-self: center;
}

.project-images img {
    border-style: solid;
    border-color: #484848;
}

figure {
    display: inline-block;
}

figure img {
    vertical-align: top;
    border-style: solid;
    border-color: #484848;
}

figure figcaption {
    text-align: center;
    color: rgb(190, 190, 190);
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.9); /* Black with opacity */
    display: flex; /* Flexbox layout */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}

.modal-content {
    max-width: 100%; /* Maximum width of the image */
    max-height: 100%; /* Maximum height of the image */
    align-items: center;
    display: block;
}

#caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
}

.modal-content, #caption {
    animation-name: zoom;
    animation-duration: 0.6s;
}

@keyframes zoom {
    from { transform: scale(0) } 
    to { transform: scale(1) }
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

