.caro-div{
    display: flexbox;
    padding: 0;
    list-style: none;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 2rem;
}

swal2-container swal2-center swal2-backdrop-show

.swal2-container{
    display: inherit;
    padding: 1rem;
}

.swal2-popup{
    display: inherit;
    padding: 1rem;
}

.swal2-backdrop-show{
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 1rem;
}

.caro-div ul {
    display: flex;
    padding: 0;
    list-style: none;
    justify-content: left;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 2rem;
    max-width: 100%;
    flex-wrap: wrap;
}

.caro-div li {
    display: flex;
    flex-direction: column;
}

.ani-div{
    display: flexbox;
    padding: 0;
    list-style: none;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 2rem;
}


.ani-div ul {
    display: flex;
    padding: 0;
    list-style: none;
    justify-content: left;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 2rem;
    max-width: 100%;
    flex-wrap: wrap;
}

.ani-div li {
    display: flex;
    flex-direction: column;
}

.love-note {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the items inside love-note */
    justify-content: center; /* Centers content vertically */
    text-align: center;
}

.love-note-ani {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the items inside love-note */
    justify-content: center; /* Centers content vertically */
    text-align: center;
}

.poem{
    white-space: nowrap;
}

.caro-div-intro{
    display: grid;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-items: center;
    justify-content: center;
}

.intro-text{
    display: grid;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-items: center;
    justify-content: center;
}

.caro-imgs img {
    max-width: 100%;
    height: auto;
}

.caro-div-intro img {
    max-width: 100%;
    height: auto;
    justify-content: center;
}

.timer-div{
    max-width: 100%;
    align-items: center;
    padding: 0px 10px;
    margin-top: 0px;
    border: 5px solid;
    border-color: black;
    border-radius: 10px;
}

.timer-div-ani{
    max-width: 100%;
    align-items: center;
    padding: 0px 10px;
    margin-top: 0px;
    border: 5px solid;
    border-color: black;
    border-radius: 10px;
}


@media (max-width: 768px) {
    
    .caro-div-intro{
        display: inherit;
        animation-fill-mode: both;
        padding: 0;
    }

    
    .caro-div{
        display: inherit;
        align-items: center;
        flex-wrap: wrap;
    }

    .ani-div{
        display: inherit;
        align-items: center;
        flex-wrap: wrap;
    }
    
}